// 引入 cookie 設定
import exp from 'constants'
import { setCookie, getCookie, delCookie } from '../../global/cookie'

class ParticipantApi {
  /**
   * 取得顧客 dataTable
   */
  getDataTable(searchData) {
    const getDataTableApi = `${process.env.VUE_APP_API}/participant/dataTable`
    const header = {
      authorization: getCookie("serverToken")
    }
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "POST",
        async: true,
        url: getDataTableApi,
        headers: header,
        data: JSON.stringify(searchData),
        contentType: 'application/json',
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 取賣場顧客所有有關合併帳號資料
   */
  getStoreParticipantContainMergeInfo(data) {
    const getStoreParticipantContainMergeInfoApi = `${process.env.VUE_APP_API}/participant/containMergeInfo`
    const header = {
      authorization: getCookie("serverToken")
    }
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "POST",
        async: true,
        url: getStoreParticipantContainMergeInfoApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 設定顧客審核狀態
   */
  setVerify(data) {
    const setVerifyApi = `${process.env.VUE_APP_API}/participant/setVerify`
    const header = {
      authorization: getCookie("serverToken"),
    }
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "PUT",
        async: true,
        url: setVerifyApi,
        headers: header,
        data: data,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 刪除 / 復原顧客
   */
  setDeleted(data) {
    const setDeletedApi = `${process.env.VUE_APP_API}/participant/setDeleted`
    const header = {
      authorization: getCookie("serverToken")
    }
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "PUT",
        async: true,
        url: setDeletedApi,
        headers: header,
        data: data,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 調整購物金
   */
  updateWallet(data) {
    const updateWalletApi = `${process.env.VUE_APP_API}/participant/updateWallet`
    const header = {
      authorization: getCookie("serverToken")
    }
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateWalletApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 設定顧客屬性
   * storeVip, customerGroup, nickName, forbidden
   */
  setAttribute(data) {
    const setAttributeApi = `${process.env.VUE_APP_API}/participant/setAttribute`
    const header = {
      authorization: getCookie("serverToken")
    }
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: 'PUT',
        async: true,
        url: setAttributeApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 新增虛擬顧客
   */
  createVirtualUser(data) {
    const createVirtualUserApi = `${process.env.VUE_APP_API}/participant/createVirtualUser`
    const header = {
      authorization: getCookie("serverToken")
    }
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "POST",
        async: true,
        url: createVirtualUserApi,
        headers: header,
        data: data,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 變更虛擬顧客名稱
   */
  updateVirtualUserName(data) {
    const editNameApi = `${process.env.VUE_APP_API}/participant/updateVirtualUserName`
    const header = {
      authorization: getCookie("serverToken")
    }
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "PUT",
        async: true,
        url: editNameApi,
        headers: header,
        data: data,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 合併顧客(實體併虛擬，虛擬併虛擬，line 併 fb)
   */
  mergeParticipant(data) {
    const mergeVirtualUserToPhysicalUserApi = `${process.env.VUE_APP_API}/participant/mergeParticipant`
    const header = {
      authorization: getCookie("serverToken")
    }
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: 'POST',
        async: true,
        url: mergeVirtualUserToPhysicalUserApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: resolve,
        error: resject,
      });
    })
    return result
  }
}

const participant = new ParticipantApi()

export { participant }