// 引入 cookie 設定
import { setCookie, getCookie, delCookie } from '../global/cookie'

let ajax

// 執行 NextSiteObjects
function getNextSiteObjects(next) {
  const getNextSiteObjectsApi = `${process.env.VUE_APP_API}/search/getNextSiteObjects`
  const header = {
    authorization: getCookie('serverToken')
  }
  const result = new Promise((resolve, reject) => {
    ajax = $.ajax({
      type: 'POST',
      async: true,
      url: getNextSiteObjectsApi,
      headers: header,
      data: `nextToken=${next}`,
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      success: resolve,
      error: reject,
    })
  })
  return result
}

// 終止 NextSiteObjects
function abortGetNextSiteObjects() {
  try {
    ajax.abort()
  } catch (error) {
    console.log('沒有可以 abort 的請求')
  }
}

// 終止所有非同步動作
function breakOff(ids) {
  abortGetNextSiteObjects()
  if (!ids) return
  ids.forEach(id => {
    clearTimeout(id)
  })
}

// 進度條進度更新
function handleProgressBar(current, total) {
  if (total === 0) return 100
  else return Math.round((current / total) * 100)
};

export { getNextSiteObjects, breakOff, handleProgressBar }