import moment from "moment"
// 判斷裝置用
import { checkIsMobileDevice } from './mobileDevice'

/**
 * 
 * @param {timestamp或一般格式} date
 * @param {預設 +0}  utc
 * @returns timestamp
 */
// 轉換時間格式 (兼容 IOS 的時間格式 / 轉成 +0 時間)
function convertTimeZone (date, utc = "+0") {
  let time = date

  if (utc === "+8") time = moment(date).format('YYYY/MM/DD HH:mm:ss')
  else if (utc === "+0") time = moment(date).subtract(8, 'hours').format('YYYY/MM/DD HH:mm:ss')
  // return new Date(time) * 1 + new Date().getTimezoneOffset() * 60 * 1000
  return new Date(time) * 1
}

export { convertTimeZone }