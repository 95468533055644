import Swal from 'sweetalert2'

export default {
  namespaced: true,
  state: {
    // 同步結果
    syncFbMessagesResult: null,
    // 檢查結果
    groupResult: null,
    pageResult: null,
  },
  mutations: {
    // 紀錄同步 FB 留言結果
    RECORDSYNCFBMESSAGES(state, payload) {
      state.syncFbMessagesResult = payload
    },
    // 特別紀錄 check 社團的結果
    RECORDGROUP(state, payload) {
      console.log(payload)
      if (payload !== null) {
        if (payload.code === '200') {
          let errors = payload.data[0].progressState.errorFeeds.filter(item => {
            // 過濾掉 FB 錯誤訊息是"文章被刪除的"商品
            return item.response && item.response.error && !(item.response.error.code === 100 && item.response.error.error_subcode === 33)
          })
          state.groupResult = {
            linkId: payload.data[0].communityLink.id,
            name: payload.data[0].communityLink.name,
            percent: payload.data[0].progressState.progress,
            error: errors
          }
        } else if (payload.code === '1') {
          state.groupResult = {
            linkId: null,
            name: payload.value,
            percent: null,
            error: []
          }
        } else {
          state.groupResult = {
            linkId: null,
            name: null,
            percent: null,
            error: null
          }
        }
      }
    },
    // 特別紀錄 check 粉專的結果
    RECORDPAGE(state, payload) {
      if (payload === null) return
      if (payload.code === '200') {
        let errors = payload.data[0].progressState.errorFeeds.filter(item => {
          // 過濾掉 FB 錯誤訊息是"文章被刪除的"商品
          return item.response && item.response.error && !(item.response.error.code === 100 && item.response.error.error_subcode === 33)
        })
        state.pageResult = {
          linkId: payload.data[0].communityLink.id,
          name: payload.data[0].communityLink.name,
          percent: payload.data[0].progressState.progress,
          error: errors
        }
      } else if (payload.code === '1') {
        state.pageResult = {
          linkId: null,
          name: payload.value,
          percent: null,
          error: []
        }
      } else {
        state.pageResult = {
          linkId: null,
          name: null,
          percent: null,
          error: []
        }
      }
    },
  },
  actions: {
    /**
     * @param {
     *  serverToken: string,
     *  data: api 要的 data,
     *  currentPage: merchandise(第一次檢查，第二次執行同步), backgroundHandle(只檢查),
     *  checkIndex: number(若大於 0，則會顯示 toast，防止第一次檢查就跳 toast 出來)
     * } payload 
     */
    // 同步 FB 留言
    syncFbMessages(context, payload) {
      context.commit('RECORDSYNCFBMESSAGES', null)
      context.commit('RECORDGROUP', null)
      context.commit('RECORDPAGE', null)
      const syncFbMessagesApi = `${process.env.VUE_APP_API}/apps/fb/synchronizeCommunity`;
      const header = {
        authorization: payload.serverToken
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: syncFbMessagesApi,
        headers: header,
        data: JSON.stringify(payload.data),
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            context.dispatch('handleResult', {
              res: res,
              payload: payload
            })
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          if (err.responseJSON && err.responseJSON.message) {
            if (
              err.responseJSON.message !== 'fb錯誤訊息: 尚未綁定社群' &&
              err.responseJSON.message !== 'fb錯誤訊息: 系統帳號尚未綁定fb帳號' &&
              err.responseJSON.message !== '參與者權限不足'
            ) alert(err.responseJSON.message)
            if (err.responseJSON.message === 'fb錯誤訊息: 尚未綁定社群') {
              console.log(payload)
              const object = {
                code: '1',
                value: `尚未綁定${payload.data[0].communityType === 35 ? '社團' : '粉專'}`
              }
              if (payload.data[0].communityType === 35) context.commit('RECORDGROUP', object)
              else context.commit('RECORDPAGE', object)
            }
          }
          else alert('同步出現錯誤，請聯繫客服')
        },
      })
    },
    // 處理同步結果
    handleResult(context, parentPayload) {
      console.log(parentPayload)
      let res = parentPayload.res
      // res.data.length > 0 代表是在做檢查，反之則是同步
      let progress = res.data.length > 0 ? res.data[0].progressState.progress : 0
      let status = res.data.length > 0 ? '檢查' : '同步'
      let payload = parentPayload.payload
      if (payload.currentPage === 'merchandise') {
        if (status === '檢查' && progress === 0) {
          // 檢查結果: 沒有任何進度 > 可以執行同步
          let nextData = payload.data
          nextData[0].check = false
          context.dispatch('syncFbMessages', {
            serverToken: payload.serverToken,
            data: nextData,
            currentPage: payload.currentPage,
            checkIndex: payload.checkIndex,
          })
        } else if (status === '檢查' && progress > 0) {
          // 檢查結果: 有進度
          if (progress === 100) {
            // 進度: 100 > 可執行同步
            let nextData = payload.data
            nextData[0].check = false
            context.dispatch('syncFbMessages', {
              serverToken: payload.serverToken,
              data: nextData,
              currentPage: payload.currentPage,
              checkIndex: payload.checkIndex,
            })
          } else {
            // 進度: 小於 100 > 不可執行同步
            Swal.fire({
              icon: 'warning',
              title: '尚未同步完成',
              html: `<p class="fw-bolder">目前進度: ${res.data[0].value}%</p>`,
              showConfirmButton: true
            })
          }
        } else if (status === '同步') {
          Swal.fire({
            icon: 'success',
            title: '已開始同步留言',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false
          })
          context.commit('RECORDSYNCFBMESSAGES', res)
          let nextData = payload.data
          nextData[0].check = true
          context.dispatch('syncFbMessages', {
            serverToken: payload.serverToken,
            data: nextData,
            currentPage: 'backgroundHandle',
            checkIndex: payload.checkIndex,
          })
        }
      } else if (payload.currentPage === 'backgroundHandle') {
        // 背景處理元件 > 只需要檢查是否還在同步就好
        if (status === '檢查' && progress > 0 && progress < 100) {
          // 檢查結果: 有進度
          if (progress < 100) {
            // 進度: 小於 100 > 繼續檢查
            setTimeout(() => {
              let nextData = payload.data
              nextData[0].check = true
              context.dispatch('syncFbMessages', {
                serverToken: payload.serverToken,
                data: nextData,
                currentPage: payload.currentPage,
                checkIndex: payload.checkIndex,
              })
            }, 1000)
          }
        } else if (status === '檢查' && progress === 100) {
          // 檢查完成同步
          console.log(payload.checkIndex, payload)
          if (payload.checkIndex > 0 || (payload.data[0].merchandiseIds && payload.data[0].merchandiseIds.length > 0)) {
            Swal.fire({
              icon: 'success',
              title: '已完成同步',
              html: `<p class="fw-bolder">${res.data[0].communityLink.name}，已完成同步留言<span class="text-danger">(記得重新整理商品管理頁面呦)</span></p>`,
              timer: 3000,
              timerProgressBar: true,
              toast: true,
              position: 'top-end',
            })
          }
        }
        if (payload.data[0].communityType === 35) context.commit('RECORDGROUP', res)
        else context.commit('RECORDPAGE', res)
      }
      // 第一次為 0 做完第一次後在 ++，目的是第一次不執行 toast
      payload.checkIndex ++
    },
  },
}