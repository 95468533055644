/**
 * @param {number} 數字 
 * @param {digits} 要取得的位數 (沒填則預設為小數後兩位) 
 * @returns 
 */
export function numberToFixed(number, digits) {
  let numberOfDigits = 0 // 紀錄預設取得小數後幾位
  if (digits > 0) {
    numberOfDigits = 1
    for (let i = 1; i <= digits; i++) {
      numberOfDigits *= 10
    }
  } else if (!digits && digits !== 0) {
    numberOfDigits = 100 // 若沒有 number 則預設取小數後兩位
  } else if (digits === 0) numberOfDigits = 1
  // 是做小數後兩位無條件捨去
  if (number === '') return
  var m = Number((Math.abs(number) * numberOfDigits).toPrecision(15));
  return Math.round(m) / numberOfDigits * Math.sign(number);
}


// 下面這組不精確 用 2.55 下去測試就知道了
// console.log(Math.floor(Number(number) * 100) / 100)
// return Math.floor(Number(number) * 100) / 100