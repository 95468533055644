import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuex_store from './store'
import PrimeVue from 'primevue/config';
import $ from 'jquery'
// mixin
import { SweetAlert } from './methods/sweetAlert/SweetAlert';
import { CreateComponents } from './methods/mixins/createComponents'
import { RecordAjax } from './methods/mixins/recordAjax'
// 引入 FB SDK
import './methods/FB/FBsdk';
// 引入 bootstrap 元件
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
// 引入 sweet alert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// 引入 lodash
import _ from 'lodash'
// 引入 cookie 設定
import { setCookie, getCookie, delCookie } from './methods/global/cookie'
// 引入切換 loading
import { switchLoading } from './methods/global/loading'
// 引入轉換時區
import { convertTimeZone } from './methods/global/convertTimeZone'
// 引入判斷裝置
import { checkIsMobileDevice, checkMobileSystem } from './methods/global/mobileDevice.js'
// 數字取小數後兩位
import { numberToFixed } from './methods/global/numberToFixed';
// 取得元素座標
import { getPosition } from './methods/global/getPosition'
// 引入時間
import moment from 'moment'
// 引入元件
import SelectInfos from './components/modal/selectInfos.vue'
import AreaLoading from './components/tools/AreaLoading.vue'
import Image from "./components/tools/Image.vue"
// 引入字體
import '@/assets/scss/font/_font.scss'
import VConsole from 'vconsole';
// 路由紀錄
import { go } from './methods/global/routerHistory'
// lazy load img
import VueLazyload from 'vue-lazyload'
// google gtag
import VueGtag from "vue-gtag";
// * API
// 分頁 API
import { getNextSiteObjects, breakOff, handleProgressBar } from './methods/API/getNextSiteObjects';
// api
import { store } from './methods/API/normal/storeApi';
import { merchandise } from './methods/API/normal/merchandiseApi';
import { merchandiseDiscount } from './methods/API/normal/merchandiseDiscount';
import { merchClassificationLinks } from './methods/API/normal/merchClassificationLink';
import { merchOrder } from './methods/API/normal/merchOrderApi';
import { stock } from './methods/API/normal/stockApi';
import { bill } from './methods/API/normal/billApi';
import { participant } from './methods/API/normal/participantApi';
import { facebook } from './methods/API/normal/facebookApi';
import { line } from './methods/API/normal/lineApi';

// 全域載入 jquery
window.$ = $

const app = createApp(App)

// if (process.env.VUE_APP_MERCHINESTATUS !== 'online') new VConsole()

// 全域使用方法
app.config.globalProperties.$methods = {
  setCookie, getCookie, delCookie, switchLoading, moment, convertTimeZone, checkIsMobileDevice, numberToFixed,
  breakOff, handleProgressBar, go, checkMobileSystem, getPosition
}
// 全域使用系統 api
app.config.globalProperties.$api = {
  getNextSiteObjects, merchandise, merchClassificationLinks, bill, merchandiseDiscount, facebook,
  line, store, merchOrder, stock, participant
}

app.mixin(SweetAlert)
app.mixin(CreateComponents)
app.mixin(RecordAjax)

app.component('SelectInfos', SelectInfos)
app.component('AreaLoading', AreaLoading)
app.component("Image", Image);

// img lazy loading
const loadimage = require('../public/spinner.gif')
const errorimage = require('./assets/icon/error.png')
app.use(VueLazyload, {
  preLoad: 1,
  error: errorimage,
  loading: loadimage,
  attempt: 1
})

app.use(_)
app.use(PrimeVue)
app.use(VueSweetalert2);
app.use(vuex_store)
app.use(router)

// GA
if (process.env.VUE_APP_MERCHINESTATUS === 'online') {
  app.use(VueGtag, {
    appName: process.env.VUE_APP_MERCHINESTATUS,
    config: { 
      id: process.env.VUE_APP_GA_ID,
    },
  }, router);
}

app.mount('#app')
