/**
 * @param {HTML 元素} element 
 * @returns x, y座標
 */
function getPosition (el) {
  console.log(el)
  const element = el[0]
  let x = 0;
  let y = 0;
  x += element.offsetLeft - element.scrollLeft + element.clientLeft;
  y += element.offsetTop - element.scrollLeft + element.clientTop;

  return { x: x, y: y };
}

export { getPosition }