// 引入 cookie 設定
import { setCookie, getCookie, delCookie } from '../../global/cookie'


class MerchClassificationLinksApi {
  /**
   * 更新商品分類
   */
  updateMultipuleSelection(data) {
    const updateMerchClassificationsApi = `${process.env.VUE_APP_API}/merchClassificationLink/updateMultipuleSelection`;
    const header = {
      authorization: getCookie('serverToken')
    };
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "POST",
        async: true,
        url: updateMerchClassificationsApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: resolve,
        error: resject,
      });
    })
    return result
  }
}

const merchClassificationLinks = new MerchClassificationLinksApi()

export { merchClassificationLinks }