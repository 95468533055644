// 引入 cookie 設定
import { setCookie, getCookie, delCookie } from '../../global/cookie'


class MerchandiseApi {
  /**
   * 取得商品 dataTable
   */
  getDataTable(searchData) {
    const getDataTableApi = `${process.env.VUE_APP_API}/merchandise/dataTable`
    const header = {
      authorization: getCookie("serverToken")
    }
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "POST",
        async: true,
        url: getDataTableApi,
        headers: header,
        data: JSON.stringify(searchData),
        contentType: 'application/json',
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 取得商品其他資訊
   */
  getOtherInfo(merchandiseIds, methodsStr) {
    const getSiteObjectsApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
    const header = {
      authorization: getCookie("serverToken")
    }
    const data = [{
      type: 16,
      ids: merchandiseIds,
      methods: methodsStr
    }]
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "POST",
        async: true,
        url: getSiteObjectsApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 更新商品屬性
   */
  setAttribute(data) {
    const updateAttributesApi = `${process.env.VUE_APP_API}/merchandise/setAttribute`;
    const header = {
      authorization: getCookie("serverToken")
    };
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "PUT",
        async: true,
        url: updateAttributesApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 上傳圖片
   */
  createPictureLinks(data) {
    const uploadPicturesApi = `${process.env.VUE_APP_API}/merchandise/createPictureLinks`;
    const header = {
      authorization: getCookie("serverToken")
    };
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: 'POST',
        async: true,
        url: uploadPicturesApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 圖片設首圖
   */
  setFrontPicture(data) {
    const setFrontPictureApi = `${process.env.VUE_APP_API}/merchandise/setFrontPicture`;
    const header = {
      authorization: getCookie("serverToken")
    };
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "PUT",
        async: true,
        url: setFrontPictureApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 設定商品圖片順序
   */
  updatePictureLinksSequence(data) {
    const saveSequenceApi = `${process.env.VUE_APP_API}/merchandise/updatePictureLinksSequence`;
    const header = {
      authorization: getCookie("serverToken")
    };
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "PUT",
        async: true,
        url: saveSequenceApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 刪除商品圖片
   */
  deletePictureLinks(data) {
    const delPicturesApi = `${process.env.VUE_APP_API}/merchandise/deletePictureLinks`;
    const header = {
      authorization: getCookie("serverToken")
    };
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "DELETE",
        async: true,
        url: delPicturesApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 新增商品
   */
  create(data) {
    const createMerchandiseApi = `${process.env.VUE_APP_API}/merchandise/create`;
    const header = {
      authorization: getCookie('serverToken')
    };
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "POST",
        async: true,
        url: createMerchandiseApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 變更刪除狀態
   */
  setDeleted(data) {
    const updateMerchStatusApi = `${process.env.VUE_APP_API}/merchandise/setDeleted`;
    const header = {
      authorization: getCookie('serverToken')
    };
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "PUT",
        async: true,
        url: updateMerchStatusApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 設定預設供應商
   */
  setSupplier(data) {
    const updateD4SupplierApi = `${process.env.VUE_APP_API}/merchandise/setSupplier`;
    const header = {
      authorization: getCookie('serverToken')
    };
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "PUT",
        async: true,
        url: updateD4SupplierApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 設定 FB 留言自動建立訂單
   */
  setAutoCreateMerchOrderFromFb(data) {
    const setAutoCreateOrderFromFbApi = `${process.env.VUE_APP_API}/merchandise/setAutoCreateMerchOrderFromFb`;
    const header = {
      authorization: getCookie('serverToken')
    };
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "PUT",
        async: true,
        url: setAutoCreateOrderFromFbApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: resolve,
        error: resject,
      });
    })
    return result
  }
}

const merchandise = new MerchandiseApi()

export { merchandise }