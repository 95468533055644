// 整合前往頁面
function go(status, otherParams) {
  const toWhere = window.localStorage.getItem('toWhere') ? JSON.parse(window.localStorage.getItem('toWhere')) : null
  const fromWhere = window.localStorage.getItem('fromWhere') ? JSON.parse(window.localStorage.getItem('fromWhere')) : null
  let result = null
  // 項下兼容路由 (若有更改路由資訊，要考慮到 locastorage 還記錄舊的路由，有機會在跳轉時出錯)
  handleOldRouter()
  switch (status) {
    // 一般登入
    case 'normal':
      result = forNormalGo(toWhere)
      break;
    // 顧客 > 下單頁面的返回
    case 'customerOrderPage':
      result = forcustomerOrderPage(fromWhere, otherParams)
      break;
    // 賣家 > 結單頁面的返回
    case 'sellerCheckoutOrder':
      result = forSellerCheckoutOrder(fromWhere)
      break;
    // Line redirect
    case 'line':
      result = forLine(toWhere)
      break;
    default:
      throw new Error('routerHistory.js > go > status 未處理')
  }
  console.log(result)
  return result
}

// 向下兼容路由
function handleOldRouter() {
  const toWhere = window.localStorage.getItem('toWhere') ? JSON.parse(window.localStorage.getItem('toWhere')) : null
  const fromWhere = window.localStorage.getItem('fromWhere') ? JSON.parse(window.localStorage.getItem('fromWhere')) : null
  if (toWhere) updateOldRouter('toWhere', toWhere)
  if (fromWhere) updateOldRouter('fromWhere', fromWhere)
}

// 將舊路由紀錄改成新的路由紀錄
function updateOldRouter(where, routerInfo) {
  if (routerInfo.name === 'LinkAccounts') {
    routerInfo.name = 'S_LinkAccounts'
  } else if (routerInfo.name === 'ReceiptInfos') {
    routerInfo.name = 'S_ReceiptInfos'
  }
  console.log(where, routerInfo)
  window.localStorage.setItem(where, JSON.stringify(routerInfo))
}

// 一般前往
function forNormalGo(toWhere) {
  console.log('執行一般登入的前往')
  const identity = window.localStorage.getItem('identity') ? window.localStorage.getItem('identity') : 'customer'
  try {
    console.log(toWhere)
    if (!toWhere || toWhere.meta.currentModal === 'backend' || toWhere.name === 'Backend_Login') {
      if (identity === 'seller') return {name: 'S_LinkAccounts'}
      else return {name: 'C_StoreLists'}
    } else {
      if (identity === 'seller') {
        // currentModal = customer (顧客模組)，customerInfo (顧客的個人頁面)
        if (toWhere.meta.currentModal !== 'customer' && toWhere.meta.currentModal !== 'customerInfo') {
          return {
            name: toWhere.name,
            params: toWhere.params,
            query: toWhere.query
          }
        } else {
          return {name: 'S_LinkAccounts'}
        }
      } else {
        if (toWhere.meta.currentModal === 'customer' || toWhere.meta.currentModal === 'customerInfo') {
          return {
            name: toWhere.name,
            params: toWhere.params,
            query: toWhere.query
          }
        } else {
          return {name: 'C_StoreLists'}
        }
      }
    }
  } catch (error) {
    console.log(error)
    if (identity === 'seller') return {name: 'S_LinkAccounts'}
    else return {name: 'C_StoreLists'}
  }
}

// 顧客 > 下單頁面
function forcustomerOrderPage(fromWhere, otherParams) {
  console.log('執行下單頁面的返回')
  try {
    // 會這樣寫是因為要讓他回去原本的分類頁面
    if (fromWhere && fromWhere.name === 'C_Shop') {
      let router = {
        name: fromWhere.name,
        params: fromWhere.params,
        query: fromWhere.query
      }
      router.query.merchandiseId = otherParams.merchandiseId
      return router
    }
    else return {name: 'C_Shop'}
  } catch (error) {
    console.log(error)
    return {name: 'C_Shop'}
  }
}

// 賣家 > 結單頁面
function forSellerCheckoutOrder(fromWhere) {
  console.log('執行賣家結單頁面返回')
  if (!fromWhere || !fromWhere.name || fromWhere.name === 'CheckoutOrder') return {name: "MerchOrder"}
  else return {
    name: fromWhere.name,
    params: fromWhere.params,
    query: fromWhere.query
  }
}

// line 登入的轉址
function forLine(toWhere) {
  console.log('執行 line redirect')
  const identity = window.localStorage.getItem('identity') ? window.localStorage.getItem('identity') : 'customer'
  if (!toWhere || toWhere.name === 'NotFound') {
    if (identity === 'seller') return {name: 'S_LinkAccounts'}
    else return {name: 'C_StoreLists'}
  }
  else {
    if (identity === 'seller') {
      if (toWhere.meta.currentModal !== 'customer' && toWhere.meta.currentModal !== 'customerInfo') return toWhere.fullPath
      else return {name: 'S_LinkAccounts'}
    } else {
      if (toWhere.meta.currentModal === 'customer' || toWhere.meta.currentModal === 'customerInfo') return toWhere.fullPath
      else return {name: 'C_StoreLists'}
    }
  }
}

export { go }