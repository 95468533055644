<template>
  <div class="mb-3">
    <div class="accordion" id="SelectInfos">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button tw-bg-main"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            <p class="fw-bolder">
              已選擇 {{ propsSelectInfos.data.length }} 筆
            </p>
          </button>
        </h2>
        <div
          id="collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <span class="fw-bolder" v-if="propsSelectInfos.show === 'name'"
              >名稱：</span
            >
            <span class="fw-bolder" v-else-if="propsSelectInfos.show === 'id'"
              >編號：</span
            >
            <span>
              <template v-for="data in propsSelectInfos.data" :key="data.id">
                <span v-if="propsSelectInfos.show === 'name'">
                  {{ data.name }}&ensp;
                </span>
                <span v-else-if="propsSelectInfos.show === 'id'">
                  {{ data.id }}&ensp;
                </span>
              </template>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['propsSelectInfos']
}
</script>