// 引入 cookie 設定
import { setCookie, getCookie, delCookie } from '../../global/cookie'


class FacebookApi {
  /**
   * 批次處理未處理留言訂單
   */
  setHandled(data) {
    const setUnHandleMessageApi = `${process.env.VUE_APP_API}/apps/fb/setHandled`;
    const header = {
      authorization: getCookie("serverToken")
    };
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "PUT",
        async: true,
        url: setUnHandleMessageApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 發布 FB 文章
   */
  publishToCommunity(data) {
    const postToFbApi = `${process.env.VUE_APP_API}/apps/fb/publishToCommunity`;
    const header = {
      authorization: getCookie("serverToken")
    };
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "POST",
        async: true,
        url: postToFbApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: resolve,
        error: resject,
      });
    })
    return result
  }
}

const facebook = new FacebookApi()

export { facebook }