// 引入 cookie 設定
import { setCookie, getCookie, delCookie } from '../../global/cookie'

class StockApi {
  /**
   * 取得訂單 dataTable
   */
  getDataTable(searchData) {
    const getDataTableApi = `${process.env.VUE_APP_API}/stock/dataTable`
    const header = {
      authorization: getCookie("serverToken")
    }
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "POST",
        async: true,
        url: getDataTableApi,
        headers: header,
        data: JSON.stringify(searchData),
        contentType: 'application/json',
        success: resolve,
        error: resject,
      });
    })
    return result
  }

  /**
   * 更新庫存
   */
  update(data) {
    const updateStockQuantityApi = `${process.env.VUE_APP_API}/stock/update`;
    const header = {
      authorization: getCookie("serverToken")
    };
    const result = new Promise((resolve, reject) => {
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateStockQuantityApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: resolve,
        error: reject,
      })
    })
    return result
  }
}

const stock = new StockApi()

export {stock}