// 訂閱到期日提醒
export function remainingDays(userInfo) {
  if (userInfo.subscriptionProjects.length === 0) return [false]
  // 計算剩餘天數
  const now = new Date() * 1
  const expirationTime = userInfo.subscriptionProjects[0].expirationTime
  console.log(expirationTime, now)
  const remainingDays = Math.ceil((expirationTime - now)/1000/60/60/24)
  if (remainingDays <= 15 && remainingDays >= 0) {
    if (!localStorage.getItem('remainingDays')) {
      // 紀錄這次看過的日期
      const object = {
        lastTime: now
      }
      localStorage.setItem('remainingDays', JSON.stringify(object))
      if (remainingDays >= 0) return [true, `喔喔~您的訂閱到期日剩餘 ${remainingDays} 天喔!`]
    } else {
      let remainingDays_local = JSON.parse(localStorage.getItem('remainingDays'))
      const lastTime = remainingDays_local.lastTime
      const lastTimeDay = new Date(lastTime).getDate()
      const nowDay = new Date(now).getDate()
      // 判斷上次看過此資訊的日期與今天日期是否一致
      if (lastTimeDay !== nowDay) {
        remainingDays_local.lastTime = now
        // 紀錄這次看過的日期
        localStorage.setItem('remainingDays', JSON.stringify(remainingDays_local))
        if (remainingDays >= 0) return [true, `喔喔~您的訂閱到期日剩餘 ${remainingDays} 天喔!`]
      } else return [false]
    }
  } else return [false]
}