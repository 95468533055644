export default {
  namespaced: true,
  state: {
    ownerStores: {
      loading: true,
      data: []
    },
    customerStores: {
      loading: true,
      data: []
    },
    fbStores: {
      loading: true,
      data: []
    },
    isNotOpenTrailPeriod: false,
    userInfo: null,
    currentStoreId: null,
    currentParticipantPermission: null,
  },
  mutations: {
    SETSTORELIST(state, payload) {
      console.log(payload)
      state.ownerStores = {
        loading: true,
        data: []
      }
      state.customerStores = {
        loading: true,
        data: []
      }
      state.fbStores = {
        loading: true,
        data: []
      }
      state.ownerStores = payload.seller
      state.customerStores = payload.customer
      state.fbStores = payload.fb
    },
    SETISNOTOPENTRAILPERIOD(state, payload) {
      state.isNotOpenTrailPeriod = payload
    },
    SETUSERINFO(state, payload) {
      state.userInfo = payload
    },
    SETCURRENTSTOREID(state, payload) {
      state.currentStoreId = payload.currentStoreId
    },
    SETCURRENTPERMISSION(state, payload) {
      state.currentParticipantPermission = payload
    },
  },
  actions: {
    // 紀錄賣場
    setStoreList(context, payload) {
      context.commit('SETSTORELIST', payload)
    },
    // 紀錄是否尚未開通
    setIsNotOpenTrailPeriod(context, payload) {
      context.commit('SETISNOTOPENTRAILPERIOD', payload)
    },
    // 紀錄 uerInfo
    setUserInfo(context, payload) {
      context.commit('SETCURRENTPERMISSION', null)
      payload.participants.forEach(item => {
        if (item.storeId === context.state.currentStoreId) {
          context.commit('SETCURRENTPERMISSION', item.participantPermission)
        }
      })
      context.commit('SETUSERINFO', payload)
    },
    // 紀錄目前賣場 ID
    setCurrentStoreId(context, payload) {
      context.commit('SETCURRENTSTOREID', payload)
    },
  },
}