// 引入 cookie 設定
import { setCookie, getCookie, delCookie } from '../../global/cookie'


class MerchandiseDiscountApi {
  /**
   * 批次結單
   */
  create(data) {
    const batchAddDiscountsApi = `${process.env.VUE_APP_API}/merchandiseDiscount/create`;
    const header = {
      authorization: getCookie("serverToken")
    };
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "POST",
        async: true,
        url: batchAddDiscountsApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: resolve,
        error: resject,
      });
    })
    return result
  }
}

const merchandiseDiscount = new MerchandiseDiscountApi()

export { merchandiseDiscount }