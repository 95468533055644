// 引入 cookie 設定
import { setCookie, getCookie, delCookie } from '../../global/cookie'

class StoreApi {
  /**
   * 取得商品其他資訊
   */
  getOtherInfo(storeIds, methodsStr) {
    const getSiteObjectsApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
    const header = {
      authorization: getCookie("serverToken")
    }
    const data = [{
      type: 5,
      ids: storeIds,
      methods: methodsStr
    }]
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "POST",
        async: true,
        url: getSiteObjectsApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: resolve,
        error: resject,
      });
    })
    return result
  }
}

const store = new StoreApi()

export { store }