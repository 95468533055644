// bootstrap
import modal from 'bootstrap/js/dist/modal'
import offcanvas from 'bootstrap/js/dist/offcanvas';
import tab from "bootstrap/js/dist/tab";

export const CreateComponents = {
  methods: {
    // 生成 bootstrap modal
    createModals(array) {
      array.forEach(item => {
        this[item] = new modal(this.$refs[item])
      });
    },
    // 升成 bootstrap offcanvas
    createOffcanvas(array) {
      array.forEach(item => {
        this[item] = new offcanvas(this.$refs[item])
      });
    },
    // 升成 bootstrap tab
    createTabs(array) {
      array.forEach(item => {
        this[item] = new tab(this.$refs[item])
      });
    },
  },
}