// 系統內存取 token 一律使用: localStorage
// 外掛內存取 token 一律使用: cookie

// 儲存 cookie
function setCookie(name, value) {
  localStorage.setItem(name, value)
  if (name === 'serverToken') {
    // 移除舊的 token
    const keys = document.cookie.match(/etg_token.[^;]+/gm)
    console.log(keys)
    if (keys) {
      for (let i = keys.length; i--;) {
        console.log(keys[i] + ';expires=' + new Date(0).toUTCString())
        document.cookie = keys[i] + ';expires=' + new Date(0).toUTCString()
      }
    }
    // 紀錄新的 token
    document.cookie = `etg_token_${new Date() * 1}=${value}`
  }
}
// 取得 cookie
function getCookie(name) {
  return localStorage.getItem(name)
  // const value = `; ${document.cookie}`;
  // const parts = value.split(`; ${name}=`);
  // if (parts.length === 2) return parts.pop().split(';').shift();
}
// 刪除 cookie
function delCookie(name){
  localStorage.removeItem(name)
  // var exp = new Date();
  // exp.setTime(exp.getTime() - 1);
  // var cval = getCookie(name);
  // if (cval != null) 
  //   document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString()+";path=/";
}

export { getCookie, delCookie, setCookie }
