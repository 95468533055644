/** 只處理 url 跟 imageHash，local 圖不處理' */

<template >
  <template v-if="convertUrl">
    <template v-if="lazy">
      <img v-lazy="convertUrl" />
    </template>
    <template v-else>
      <img :src="convertUrl" />
    </template>
  </template>
</template>

<script>
import { handleImageHash2Url } from "@/methods/mixins/imageHash2Url"

export default {
  props: {
    /** 若要呈現的是圖床圖片(Imgur | 公司圖床)，需搭配 size 來決定圖檔大小 
     * 備註: 原圖就不用傳了
    */
    imageHash: {
      type: [String, null],
      default: null
    },
    /** 圖床的圖片大小 (原圖不用傳)
     * 參數: s | m 
     */
    size: {
      type: [String, null],
      default: null
    },

    /** 一般 URL */
    url: {
      type: [String, null],
      default: null,
    },

    /** 是否要 lazy 載入 */
    lazy: {
      type: Boolean,
      default: true
    },
  },

  data () {
    return {
      convertUrl: null
    }
  },

  mounted () {
    this.createUrl()
  },

  mixins: [handleImageHash2Url],

  watch: {
    imageHash: {
      handler(val) {
        this.createUrl()
      }
    },
  },

  methods: {
    createUrl() {
      if (this.imageHash) this.convertUrl = handleImageHash2Url(this.imageHash, this.size)
      else if (this.url) this.convertUrl = this.url
    },
  },
}
</script>