export const SweetAlert = {
  methods: {
    SweetAlert(code, retrunObject) {
      let icon = ''
      let title = ''
      let text = ''
      let timer = 3000
      let is_redirect = false
      let showConfirmButton = false
      if (code === '200') {
        icon = 'success'
        title = '成功'
      } else if (code === '206') {
        icon = 'success'
        title = '部份成功'
        text = `<p class="text-start fw-bolder">${retrunObject}</p>`
        timer = false
        showConfirmButton = true
      } else if (code === 'setByMyself') {
        /* 
        自己客製化
        example: retrunObject = {
          icon: '',
          title: {
            show: false,
            value: ''
          },
          text: {
            show: false,
            value: ''
          },
          timer: 3000,
          showConfirmButton: false
        }
        */
        icon = retrunObject.icon
        retrunObject.title.show ? title = retrunObject.title.value : ''
        retrunObject.text.show ? text = retrunObject.text.value : ''
        timer = retrunObject.timer
        retrunObject.showConfirmButton ? showConfirmButton = true : showConfirmButton = false
      } else if (code === "403-1" || code === "403-2" || code === "403-5") {
        icon = 'error'
        title = retrunObject
        is_redirect = true
      } else {
        icon = 'warning'
        text = retrunObject
        if (retrunObject === 'org.springframework.data.redis.serializer.SerializationException: Cannot deserialize; nested exception is org.springframework.core.serializer.support.SerializationFailedException: Failed to deserialize payload. Is the byte array a result of corresponding serialization for DefaultDeserializer?; nested exception is java.io.OptionalDataException') {
          // redis 資料錯誤，前端需重新整理
          console.log('redis 資料錯誤，前端需重新整理')
          return window.reload()
        }
      }
      this.$swal({
        icon: `${icon}`,
        title: `${title}`,
        html: `<p class="fw-bolder">${text}</p>`,
        timer: timer,
        showConfirmButton: showConfirmButton,
        timerProgressBar: timer ? true : false,
      }).then(() => {
        const identity = window.localStorage.getItem('identity') ? window.localStorage.getItem('identity') : 'customer'
        if (is_redirect) {
          this.$methods.delCookie('serverToken')
          if (identity === 'seller') return this.$router.push('/sellerLogin')
          else return this.$router.push('/customerLogin')
        }
      })
    }
  },
}