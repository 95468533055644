import { SweetAlert } from "../sweetAlert/SweetAlert"
import { remainingDays } from '../mixins/remainingDays'
// api
import { parseToken, sortoutParseTokenResult } from './parseToken'

export const GetStoreList = {
  data() {
    return {
      // 賣家
      ownerStores: {
        loading: false,
        data: []
      },
      // 顧客
      customerStores: {
        loading: false,
        data: []
      },
      // FB 免註冊用戶 (實體顧客)
      fbStores: {
        loading: false,
        data: []
      },
      // 此帳號是否尚未開通
      isNotOpenTrailPeriod: false,
    }
  },
  mixins: [SweetAlert],
  methods: {
    // 取得使用者資訊
    getUserInfoForStoreList() {
      this.$methods.switchLoading('show')
      this.ownerStores = {
        loading: true,
        data: []
      }
      this.customerStores = {
        loading: true,
        data: []
      }
      this.fbStores = {
        loading: true,
        data: []
      }
      const result = parseToken(this.$methods.getCookie('serverToken'))
      result.then(res => {
        console.log(res)
        const result = sortoutParseTokenResult(res)
        this.getPhysicalUserInfoForStoreList(result)
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 取得實體使用者賣場列表
    getPhysicalUserInfoForStoreList(userInfo, serverToken) {
      this.$methods.switchLoading('show')
      const vm = this
      const getPhysicalUserInfo = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: serverToken ? serverToken : this.$methods.getCookie('serverToken')
      }
      const data = [
        {
          type: 1,
          ids: userInfo.ids,
          methods: '{getUserLineLink,getParticipantPermissions{getPaymentRecordPermissionLinks,isInPeriod,getParticipant{getStore,getUser}},getParticipants{getParticipantPermission{isInPeriod},getStore{getCurrentSubscriptionProject}},getCurrentSubscriptionProject,getSubscriptionProjects,getPaymentRecords{isPaid}}'
        }
      ]
      this.ownerStores.loading = true
      this.customerStores.loading = true
      this.fbStores.loading = true
      $.ajax({
        type: 'POST',
        async: true,
        url: getPhysicalUserInfo,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const now = new Date() * 1
            const masterUserInfo = res.data[0].objects[0] // 取主帳號
            const slaveUserInfo = res.data[0].objects[1] // 取被合併帳號
            masterUserInfo.participantPermissions.forEach(item => { item.selected = false })
            vm.$store.dispatch('StoreList/setUserInfo', masterUserInfo)
            // 檢查訂閱到期日提醒
            const remainingDaysResult = remainingDays(masterUserInfo)
            if (remainingDaysResult[0]) vm.SweetAlert('other', remainingDaysResult[1])
            // 整理賣場
            let ownerStores = []
            let customerStores = []
            let fbStores = []
            masterUserInfo.participants.forEach(item => {
              // 判斷賣場有沒有符合購買方案的時間區間內
              let is_inSubscription = false
              if (item.store.currentSubscriptionProject) {
                const enableTime = item.store.currentSubscriptionProject.enableTime
                const expirationTime = item.store.currentSubscriptionProject.expirationTime
                is_inSubscription = enableTime <= now && now <= expirationTime
              }
              // 賣家少了 store.deleted / 自己賣場是否訂閱到期 判斷 (含小幫手)
              if ((item.authority === 'OWNER' || (item.authority === 'CUSTOMER' && item.participantPermission && item.participantPermission.inPeriod)) && item.verify && !item.deleted) {
                // 在 participant 紀錄的 is_inSubscription 是依照本帳號的訂閱狀況，考慮他若沒訂閱但有小幫手的情況下
                item.is_inSubscription = is_inSubscription
                ownerStores.push(item)
              }
              // 顧客少了 verify 的判斷
              if (!item.deleted && !item.store.deleted && is_inSubscription) {
                customerStores.push(item)
                fbStores.push(item)
              }
            })
            vm.ownerStores.data = ownerStores
            vm.customerStores.data = customerStores
            vm.fbStores.data = fbStores
            vm.ownerStores.loading = false
            vm.customerStores.loading = false
            vm.fbStores.loading = false
            vm.$store.dispatch('StoreList/setStoreList', {
              seller: vm.ownerStores,
              customer: vm.customerStores,
              fb: vm.fbStores
            })
            vm.checkIsNotOpenTrailPeriod(masterUserInfo)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 檢查是否尚未開通試用期
    checkIsNotOpenTrailPeriod(masterUserInfo) {
      if (this.ownerStores.data.length > 0) {
        this.isNotOpenTrailPeriod = masterUserInfo.paymentRecords.some(paymentRecord => {
          return paymentRecord.planType === 'FREE_TRIAL' && !paymentRecord.deleted && !paymentRecord.paid
        })
        this.$store.dispatch('StoreList/setIsNotOpenTrailPeriod', this.isNotOpenTrailPeriod)
      }
    },
  },
}