/**
 * 解析 token
 * @param {*} serverToken 
 * @returns token 的解析結果
 */
function parseToken(serverToken) {
  const getUserInfoApi = `${process.env.VUE_APP_API}/user/parseToken?token=${serverToken}`
  const result = new Promise((resolve, reject) => {
    $.ajax({
      type: "GET",
      async: true,
      url: getUserInfoApi,
      success: resolve,
      error: reject,
    });
  })
  return result
}

function sortoutParseTokenResult(res) {
  if (res.code === '200') {
    const userInfo = res.data
    console.log(userInfo)
    let userType = null // userType 目前只取得主帳號的 userType
    let ids = [] // ids index=0 時紀錄的是"主帳號 id"，index=1 時紀錄的是"被合併帳號 id"
    if (userInfo.aud) {
      userType = parseInt(userInfo.aud.split(':')[0])
      ids.push(userInfo.aud.split(':')[1])
    }
    if (userInfo.slaves) {
      ids.push(userInfo.slaves.split(':')[1])
    }
    userInfo.userType = userType
    userInfo.ids = ids
    return userInfo
  }
}

export { parseToken, sortoutParseTokenResult }