// 引入 cookie 設定
import { setCookie, getCookie, delCookie } from '../../global/cookie'


class LineApi {
  /**
   * 加入 line 待推播列表
   */
  addMerchandiseToLinePushQueue(data) {
    const linePushApi = `${process.env.VUE_APP_API}/apps/line/addMerchandiseToLinePushQueue`
    const header = {
      authorization: getCookie("serverToken")
    }
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: 'POST',
        async: true,
        url: linePushApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 發布 line notify
   */
  notify(data) {
    const postToNotifyApi = `${process.env.VUE_APP_API}/apps/line/notify`;
    const header = {
      authorization: getCookie("serverToken")
    };
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "POST",
        async: true,
        url: postToNotifyApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: resolve,
        error: resject,
      });
    })
    return result
  }
}

const line = new LineApi()

export { line }