// 紀錄目前所執行的 ajax <致命缺點: 重整就沒了>
export const RecordAjax = {
  data() {
    return {
      /**
       * path: api 路徑
       * value: [{
       *  id: 自設,
       *  ajax: ajax,
       * }...]
       */
      ajaxQueue: new Map(),
    }
  },
  methods: {
    // 檢查是否有 ajax 再執行
    checkAjaxQueue(path, id) {
      if (!this.ajaxQueue.get(path)) return false
      else return this.ajaxQueue.get(path).some(item => { return item.id === id })
    },
    // 新增 ajax
    addAjaxQueue(path, id, ajax) {
      if (!this.ajaxQueue.has(path)) {
        this.ajaxQueue.set(path, [])
      }
      this.ajaxQueue.get(path).push({
        id, ajax
      })
    },
    // 移除 ajax
    removeAjaxQueue(path, id) {
      let arr = []
      const key = this.ajaxQueue.get(path)
      if (key) {
        arr = this.ajaxQueue.get(path).filter(item => { return item.id !== id })
      }
      this.ajaxQueue.delete(path)
      if (arr.length > 0) this.ajaxQueue.set(path, arr)
    },
  },
}