// 檢查使用的裝置是否為行動裝置
function checkIsMobileDevice() {
  // alert(navigator.userAgent)
  const mobileDevices = ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone', 'Macintosh']
  const isMobileDevice = mobileDevices.some(device => {return navigator.userAgent.match(device)})
  return isMobileDevice
}

// 檢查是否為 IOS 或是 Android
function checkMobileSystem() {
  const ua = navigator.userAgent;
  const android = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1; // android
  const ios = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios
  if (android) return 'android'
  else if (ios) return 'ios'
  else return 'desktop'
}

export { checkIsMobileDevice, checkMobileSystem }