/**
 * 將圖片 hash 轉成 url (因為目前有 Imgur 與 公司圖床，兩個 url)
 */

/**
     * 處理 imageHash 轉 url
     * @param string imageHash
     * @param string size (s | m)
     * @return string convertUrl 
     */
function handleImageHash2Url(imageHash, size=null) {
  // console.log(imageHash, size)
  let convertUrl = null
  if (imageHash) {
    // 如果 imageHash 長度小於 15 表示是 imgur 圖片，反之則是圖床圖片
    if (imageHash.length < 15) convertUrl = size ? `https://i.imgur.com/${imageHash}${size}.jpg` : `https://i.imgur.com/${imageHash}.jpg`
    else convertUrl = hanldeImgUrl(imageHash, size)
  }
  return convertUrl
}
/**
 * 處理公司圖床大小圖
 * @param string imageHash
 * @param string size (s | m)
 * @return string url 
 */
function hanldeImgUrl(imageHash, size=null) {
  let url = `https://pic.twingo.com.tw${imageHash}`

  let replaceIndex = url.indexOf("_") - 1
  if (size) url = url.substring(0, replaceIndex) + size + url.substring(replaceIndex + 1)

  // console.group("image")
  // console.log(url.substring(0, replaceIndex))
  // console.log(size)
  // console.log(url.substring(replaceIndex + 1))
  // console.groupEnd()

  return url
}

export { handleImageHash2Url }