export default {
  namespaced: true,
  state: {
    participantInfo: null
  },
  mutations: {
    SETPARTICIPANTINFO(state, payload) {
      state.participantInfo = payload
    },
  },
  actions: {
    verify(context, payload) {
      const verifyUserApi = `${process.env.VUE_APP_API}/participant/verify`;
      const header = {
        authorization: payload.serverToken
      }
      const data = new FormData()
      data.append('storeId', payload.storeId)
      $.ajax({
        type: 'POST',
        async: false,
        url: verifyUserApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          context.commit('SETPARTICIPANTINFO', res)
        },
        error: function(err) {
          console.log(err.responseJSON)
          context.commit('SETPARTICIPANTINFO', err.responseJSON)
        },
      })
    },
  },
}