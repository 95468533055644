// 引入 cookie 設定
import { setCookie, getCookie, delCookie } from '../../global/cookie'

class MerchOrderApi {
  /**
   * 取得訂單 dataTable
   */
  getDataTable(searchData) {
    const getDataTableApi = `${process.env.VUE_APP_API}/merchOrder/dataTable`
    const header = {
      authorization: getCookie("serverToken")
    }
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "POST",
        async: true,
        url: getDataTableApi,
        headers: header,
        data: JSON.stringify(searchData),
        contentType: 'application/json',
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 取得訂單其他資訊
   */
  getOtherInfo(merchOrderIds, methodsStr) {
    const getSiteObjectsApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
    const header = {
      authorization: getCookie("serverToken")
    }
    const data = [{
      type: 26,
      ids: merchOrderIds,
      methods: methodsStr
    }]
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "POST",
        async: true,
        url: getSiteObjectsApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: resolve,
        error: resject,
      });
    })
    return result
  }
  /**
   * 斷貨
   */
  setSoldOut(merchOrderIds) {
    const soldOutApi = `${process.env.VUE_APP_API}/merchOrder/setSoldOut`;
    const header = {
      authorization: getCookie("serverToken")
    }
    const merchOrderIdsStr = merchOrderIds.toString()
    const data = new FormData();
    data.append('merchOrderIds', merchOrderIdsStr)
    const result = new Promise((resolve, reject) => {
      $.ajax({
        type: "PUT",
        async: true,
        url: soldOutApi,
        headers: header,
        data: data,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: resolve,
        error: reject,
      })
    })
    return result
  }
  /**
   * 配單
   */
  setAllocatedQuantity(data) {
    const setAllocatedQuantityApi = `${process.env.VUE_APP_API}/merchOrder/setAllocatedQuantity`;
    const header = {
      authorization: getCookie("serverToken")
    };
    const result = new Promise((resolve, reject) => {
      $.ajax({
        type: "PUT",
        async: true,
        url: setAllocatedQuantityApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: resolve,
        error: reject,
      })
    })
    return result
  }
  /**
   * 編輯訂單
   */
  update(data) {
    const updateOrderApi = `${process.env.VUE_APP_API}/merchOrder/update`;
    const header = {
      authorization: getCookie("serverToken")
    }
    const result = new Promise((resolve, reject) => {
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateOrderApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: resolve,
        error: reject,
      })
    })
    return result
  }
  /**
   * 刪除訂單
   */
  deleted(merchOrderIds) {
    const delMerchOrderApi = `${process.env.VUE_APP_API}/merchOrder/delete`;
    const header = {
      authorization: getCookie("serverToken")
    };
    const merchOrderIdsStr = merchOrderIds.toString()
    const data = new FormData()
    data.append("merchOrderIds", merchOrderIdsStr)
    const result = new Promise((resolve, reject) => {
      $.ajax({
        type: 'DELETE',
        async: true,
        url: delMerchOrderApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: resolve,
        error: reject,
      })
    })
    return result
  }
}

const merchOrder = new MerchOrderApi()

export { merchOrder }